<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ $t('eps_terms_agent') }}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div style="margin: 30px 10px;">
      <template v-if="isZhCn">
        <div class="container p-t-2 p-b-2">
          <div class="row">
            <div class="column">
              <div class=WordSection1>
                <p align=center style='text-align:center;
'><b><span >代理使用条款</span></b></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
'><span >这些代理使用条款（<b>这些条款</b>）适用于您作为实体通过我方（<b>您</b>）已获认可的平台使用预订工具（）向终端用户营销酒店预订。<b>我方</b> </span></p>
                <p style='text-align:justify;'><span >请阅读这些条款。有定义的条款英语标注为大写。如果文中未显示这些条款的定义，则在末尾显示。通过已获认可的平台向终端客户提供酒店预订，表示您同意、承认并向我方表示：</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><span
                    style='font-size:8.0pt;font-family:"Calibri",sans-serif;
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang=ZH-CN style='font-size:8.0pt;
'>您的代表已代表您阅读并理解这些条款；</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><span
                    style='font-size:8.0pt;font-family:"Calibri",sans-serif;
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang=ZH-CN style='font-size:8.0pt;
'>您同意在不修改您通过已获认可的平台访问酒店预订的路径并不改变向终端用户提供报价的情况下，遵守这些条款；同时，</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><span
                    style='font-size:8.0pt;font-family:"Calibri",sans-serif;
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang=ZH-CN style='font-size:8.0pt;
'>您的代表通过接受这些条款达成协议，拥有接受这些条款并约束您的权力、权限和权威。</span><span
                >  </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
                >代理义务</span></b></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >1.1<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >代理营销</span></b><span
                >。在条款期限内，您应主动向终端客户营销并推广酒店预订。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span >1.2<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >内容。</span></b><span
                >您将不会变更、改变或虚假陈述任何通过已获认可的平台获得并与酒店预订相关的信息、内容或数据（<b>内容</b>）。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >1.3<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >访问酒店预订和已获认可的平台的路径。</span></b><span
                >您必须：</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(a)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>仅：</span><span >(i) <span lang=ZH-CN>通过已获认可的平台访问酒店预订；同时，</span>(ii) <span lang=ZH-CN>遵循条款第 </span></span><span
                >2</span><span
                > <span lang=ZH-CN>部分中的《道德准则》以及我方每次向您提供的说明，获取预订。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(b)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>仅在已获认可的平台输入终端客户的数据，用途仅为获取预订；同时，</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(c)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>保证提交给已获认可的平台的终端用户数据和其他信息完整且准确。</span><span
                >    </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >1.4<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >用户合同</span></b><span lang=ZH-CN style='font-size:8.0pt;
'>。在完成预订前，您必须：</span><span > </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(a)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>明确地向终端客户表示，任何相关司法管辖区的任何税务机关在按照欧盟指令</span><span > 2006/112/EC <span lang=ZH-CN>征收增值税时均须将</span> Travelscape, LLC <span
                    lang=ZH-CN>或 </span>Vacationspot, SL<span lang=ZH-CN>（或任何其他</span> EPS <span
                    lang=ZH-CN>的集团成员）视为电子收费预订和酒店预订套餐部分终端客户的供应商；</span></span><span
                > </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(b)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>不以明示或暗示的形式显示或进行与这些条款中与第 </span><span
                >1.4</span><span
                >(a)</span><span
                > <span lang=ZH-CN>条相矛盾的陈述；</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(c)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>保证终端客户已阅读并同意遵守终端客户条款和条件；</span><span
                >  </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(d)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>保证终端客户知晓全部适用于其预订的限制条件，包括是否允许终端客户取消或更改他们的预订，以及由此带来的后果；同时</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(e)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>不对终端客户作出下列描述之外的或与之相矛盾的任何口头或书面形式的保证：</span><span
                >(i) <span lang=ZH-CN>这些条款；或者</span> (ii) <span lang=ZH-CN>终端客户条款和条件；包括向终端客户保证将满足他们的特殊要求。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
'><span >在我方没有提供提前书面授权或许可的情况下，由您全权负责任何在代表、客户提出的特殊需求或向客户确认的特别需求情况下的使用。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >1.5<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >客户交流和投诉</span></b><span lang=ZH-CN style='font-size:8.0pt;
'>。</span><span lang=ZH-CN style='font-size:
8.0pt;'> </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(a)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您须在收到后</span><span > 24 <span lang=ZH-CN>小时之内：</span>(i) <span lang=ZH-CN>在无改变和修改的情况下，向终端客户提供所有与预订有关的信息；同时，</span>(ii) <span lang=ZH-CN>在无改变和修改的情况下，向我方提供这些信息：</span>(1) <span lang=ZH-CN>收到的所有与预订有关的通信；</span>(2) <span lang=ZH-CN>任何终端客户的投诉；以及</span> (3) <span lang=ZH-CN>来自任何贸易管理机构的一切与终端客户和</span>/<span
                    lang=ZH-CN>或任何预订有关的交流。</span>  </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(b)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>如果收到任何终端客户的投诉、索赔或任何有关预订的行为（所有），您须在</span><span > 24 <span lang=ZH-CN>小时之内通知我方。<b>终端客户问题</b>您承认并同意我方有权尝试直接与终端客户解决所有终端客户问题。如果我方有此种尝试，您必须向我方提供解决问题所需要的一切信息和合理协助，费用由您承担。同时，您须避免任何有关终端客户问题的行为；除非适用法律要求您这样做。</span></span><span
                > </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span
                >1.6<span
                    style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=ZH-CN style='font-size:8.0pt;
'>遵守法律</span></b><span
                >。按照这些条款履行义务时，您必须遵守当时生效的所有相关的适用法律，包括但不限于与履行代理（<b>适用法律</b>）许可相关的法律。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span
                >1.7<span
                    style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=ZH-CN style='font-size:8.0pt;
'>套餐预订</span></b><span
                >。如果我方向您（直接或间接）提供书面许可，且您仅将套餐产品作为套餐的一部分提供给终端客户，那么我方所随时指定的特定酒店预订（<b>套餐产品</b>）可能向您开放。我方随时可能停止提供套餐产品，会在七</span><span > (7) <span lang=ZH-CN>天内向您（直接或间接）提供书面通知。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(a)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
                >预订套餐产品。</span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您须：</span><span
                >(a) <span lang=ZH-CN>除非其为套餐的一部分，否则不得预订、开放、提供套餐产品；</span>(b) <span lang=ZH-CN>如果在预订或确认过程中终端用户无法看到价格，那么须保证套餐中产品的最后预订价格与单独的产品价格相等。（您承认您负责套餐的最终价格）；同时，</span>(c) <span lang=ZH-CN>仅限套餐</span></span><span
                > </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;
text-autospace:none'><span >我方可能随时需要您自费展示您一直遵守第</span><span
                >1.7</span><span
                >(a)</span><span
                > <span lang=ZH-CN>条。您须在我方的书面要求下发送预订确认、预订细条以及其他此类信息、系统和</span>/<span
                    lang=ZH-CN>或文件的访问途径，以证明您遵守第 </span></span><span
                >1.7</span><span
                >(a)</span><span
                > <span lang=ZH-CN>条。 </span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;
text-autospace:none'><span >在不损害我方或我方集团成员的任何其他权益或赔偿的情况下，如果您违反第 </span><span
                >1.7</span><span
                >(a)</span><span
                > <span lang=ZH-CN>条，那么我方可能立即限制访问套餐产品，并中止这些条款。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(b)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
                >遵守法律</span></b><span lang=ZH-CN style='font-size:8.0pt;
'>。您承担并保证您全权负责获取和持续使用所有许可、准许和同意书（如果），以及您为了提供和</span><span >/<span lang=ZH-CN>或准备的套餐预订而履行这些条款下义务时所需的必要资金安全准备。在您按照这些条款提供套餐时，您会遵守所有适用法律（包括欧洲理事会指令</span> 90/314/EEC <span lang=ZH-CN>以及所有相关的地方版本）。您须自行承担遵守第 </span></span><span >1.7</span><span >(b)</span><span > <span lang=ZH-CN>条规定所产生的费用。</span></span><span > </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;
text-autospace:none'><span >我方同意在收到询问、信息需求或来自任何国家机构、消费者机关或其他相关方有关提供套餐的调查时，提供一切必要协助和支持。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span >1.8<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >代理预订费用。</span></b><span
                >如果您对终端客户所付总额增加一项预订费用，您须保证：</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(a)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>预订费用不计入我方提供的房费、税费或服务费中， </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(b)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您应向终端客户清楚说明：预订费用由您自行收取；</span><span style='font-size:8.0pt;
'>  </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(c)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您在您的预订条款和条件中包含下列有关预订的文字：“总价包含</span><span
                >[<span lang=ZH-CN>输入您的姓名</span>]<span
                    lang=ZH-CN>支付的额外预订费用”；</span>  </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(d)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您不得将预订费用计入任何供应商遴选算法或排序顺序逻辑中。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><span > <b><span lang=ZH-CN>道德准则</span></b></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >2.1<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您不得向任何司法管辖区的终端客户发送未经索取的批量电子邮件、“垃圾邮件”，也不得以其他方式参与涉及这些终端客户的任何其他不道德或违法的营销活动（由我方或</span><span
                > EPS <span lang=ZH-CN>合理进行判断）。</span>  </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >2.2<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您不得做出以下行为：</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(a)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>在任何面向顾客的网站或附属的酒店预订上显示在任何司法管辖区视为违法或从其他方面看有歧视性、宣扬或煽动暴力、仇恨或非法的活动，可能解释为歧视或宣扬此类观点，或不适于大众或家庭观看（例如性暴露材料）的任何材料或将酒店预订业务与此类材料或观点关联。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(b)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>在您的网站、产品或服务的来源、加盟关系或性质方面误导客户或向客户做虚假陈述；</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(c)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>未经我方或相关第三方事先书面批准，在任何情况下或以任何方式（包括但不限于，在任何元数据标记、搜索引擎推广或优化、任何域名、任何其他在线</span><span
                >/<span lang=ZH-CN>离线推广或广告、新闻稿等材料中）（直接或间接）销售、分销、显示或使用来自</span> EPS <span lang=ZH-CN>或其集团成员网站的任何数据或材料、</span>EPS <span lang=ZH-CN>或其集团成员的商标、徽标、品牌或任何其他第三方（包括第三方住宿提供商）的商标、徽标或品牌（包括任何拼写错误或非常相似或容易造成混淆的相似版本）；</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(d)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>以违反本协议条款的方式销售、分销、显示或使用</span><span style='font-size:
8.0pt;'> EPS <span lang=ZH-CN>或我方集团成员拥有或运营的任何网站上的任何数据、材料或其他内容。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >2.3<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >掠夺性广告。</span></b><span
                >您不得使用任何掠夺性广告形式</span><span
                    lang=ZH-CN>。</span><span >掠夺性广告指在网站、移动设备、社交媒体或任何其他渠道上创建或叠加链接或横幅，以此允许访问酒店预订（每条渠道）、生成大量浏览器窗口或任何意图在未经渠道所有者知情、许可和参与的情况下产生大量流量的方法。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >2.4<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >关键字广告。</span></b><span
                >您不得以</span><span
                > EPS <span lang=ZH-CN>或其集团成员所有的</span> URL <span lang=ZH-CN>上显示的名称（包括“</span>travelnow.com<span lang=ZH-CN>”、“</span>hotels.com<span
                    lang=ZH-CN>”、“</span>hotel.com<span lang=ZH-CN>”、“</span>vacationspot.com<span
                    lang=ZH-CN>”、“</span>hotels.co.uk<span lang=ZH-CN>”、“</span>condosavers.com<span
                    lang=ZH-CN>”、“</span>orlando.com<span lang=ZH-CN>”、“</span>expedia<span
                    lang=ZH-CN>”、“</span>hotwire<span lang=ZH-CN>”、“</span>wotif.com<span
                    lang=ZH-CN>”“</span>orbitz<span lang=ZH-CN>”、”</span>cheaptickets<span
                    lang=ZH-CN>”、“</span>travelocity<span lang=ZH-CN>”或“</span>ebooker<span
                    lang=ZH-CN>”（或其任何地区性变体）换取在任何按点击次数计费的搜索引擎或其他按照向搜索引擎或其他第三方支付的费用决定搜索结果页面排序的搜索引擎中获得有利排序位置，并且也不得将任何上述名称用于核准网站或您所拥有或运营的任何其他网站或渠道的任何页面上的关键字元数据标记中。如果我们提出要求，您须立即停止出价购买或使用显示在任何酒店供应商</span> URL <span lang=ZH-CN>中显示名称，或此类供应商的任何品牌或名称。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >2.5<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您不得代表</span><span > EPS <span lang=ZH-CN>或任何其集团成员产生行为。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >2.6<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>在与终端客户联系时，您不得就您所代理的商家做出虚假陈述，包括（但不限于）误导终端客户相信您与酒店预订所涵盖的任何住宿提供商有直接关联。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >2.7<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您确认并同意，一切相关司法管辖区的税务机关在按照欧盟指令</span><span
                > 2006/112/EC <span lang=ZH-CN>征收增值税时均须将</span> Travelscape, LLC <span lang=ZH-CN>或</span> Vacationspot, SL<span lang=ZH-CN>（或</span> EPS <span lang=ZH-CN>指定的任何集团成员）视为电子收费预订和酒店预订套餐部分终端客户的供应商；</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >2.8<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您不得直接联系酒店预订所涵盖的任何住宿提供商。如果您收到此类住宿提供商直接向您发送的信息，应立即向我方报告。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >2.9<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>一切与酒店预订相关的广告和宣传材料都应由我方提供或书面形式的明确批准。 </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >2.10<span style='font:7.0pt "Times New Roman"'> </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您应通知我方一切您收到的有关处理电子收取预订增值税的客户投诉，绝不私下尝试解决此类投诉。您同时应向我方提交一切来自税务机关或任何政府代理的有关这些条款或电子领取预订的询问，应给我方机会检查和</span><span
                >/<span lang=ZH-CN>或促成回应此类询问。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >2.11<span style='font:7.0pt "Times New Roman"'> </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>对于根据这些条款所提供的服务，您不得就相关的任何合同条款做出虚假陈述，包括产品描述。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
                >期限和终止</span></b></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >3.1<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>这些条款应始于下列时间中较早的一个：</span><span >(i) <span
                    lang=ZH-CN>您接受这些条款；或</span> (ii) <span lang=ZH-CN>您首次预订；并须服从符合第 </span></span><span >3.2</span><span > <span lang=ZH-CN>条的提前终止。在我方和您之间的关系或</span> EPS <span lang=ZH-CN>与我方之间的关系到期或终止之前（<b>期限</b>），一直有效。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >3.2<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>我方可能在无理由同时不提供书面通知的情况下因为任何原因全权决定立即终止这些条款。您可能在三十（</span><span >30<span lang=ZH-CN>）天内提供书面通知的情况下终止这些条款。第</span> 3.2 <span lang=ZH-CN>部分中提供的权利和赔偿并非排他，可附加于法律或这些条款中的任何其他权利或赔偿。</span></span><span
                > </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span >3.3<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>如果由于任何原因，这些条款终止或过期，则：</span><span >(i) <span lang=ZH-CN>您应立即停止获得预订；同时</span> (ii) <span lang=ZH-CN>我方可能立即关闭您的帐户。第 </span></span><span >3.3</span><span style='font-size:8.0pt;'><span lang=ZH-CN>、</span></span><span style='font-size:8.0pt;'>4</span><span style='font-size:8.0pt;'><span lang=ZH-CN>、</span></span><span style='font-size:8.0pt;'>5</span><span style='font-size:8.0pt;'> <span lang=ZH-CN>和 </span></span><span style='font-size:8.0pt;'>6</span><span style='font-size:8.0pt;'> <span lang=ZH-CN>条应在这些条款过期或终止后继续使用。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
                >保密；媒体传播、数据保护</span></b></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>4.1<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >保密</span></b></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(a)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>本条款中的<b>机密信息</b>是指因这些条款及这些条款的存续、条款和标的，一方或其集团成员（以及</span><span style='font-size:8.0pt;'> EPS <span lang=ZH-CN>或其集团成员）向另一方披露或被另一方以其他方式获取的所有专门知识、文档和信息，无论是商业、财务、技术、运营还是其他方面与业务、财务、事务、工具（包括所提供的技术方面的那些工具）、产品、服务、人员、客户、供应商（包括其所在地区、将来人员和潜在人员、客户与供应商）、价格、佣金、房价、提供商、流程或方法相关的此类信息。</span></span><span
                    style='font-size:8.0pt;'>  </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(b)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您和我方（一方和另<b>一方</b>）应对另一方的保密信息保密，不得向第三方泄露此信息，</span><span style='font-size:8.0pt;'>EPS <span lang=ZH-CN>或 </span>EPS <span lang=ZH-CN>集团成员除外，除非是用于这些条款或任何其他不需要另一方提前书面许可即可泄露的目的。<b>双方</b></span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(c)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>第</span><span style='font-size:8.0pt;'>4.1</span><span style='font-size:8.0pt;'>(b)</span><span style='font-size:8.0pt;'> <span lang=ZH-CN>条不适用于接收方可以证明属于以下信息的任何机密信息：如果特定披露或公布的使用、结合、分析、形成或收集信息部属于公共领域的原因仅仅为其可以运用公共领域的信息再创造，那么不适用信息包括</span> (i) <span lang=ZH-CN>公共领域信息，结合形式与向接收方披露的形式相同，除非是由于违反这些条款或保密义务；</span>(ii) <span
                    lang=ZH-CN>在非履行此处保密义务的情况下，由第三方合法接受；</span>(iii) <span lang=ZH-CN>依照法律、法庭指令或任何在合法管辖区内监督机构披露（程度和目的符合需要）；</span>(iv) <span lang=ZH-CN>得到书面披露许可；或者</span> (v) <span lang=ZH-CN>独立发现且不设计对方披露的保密信息。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(d)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>双方均须尽可能详尽地通知对方依据第 </span><span
                    style='font-size:8.0pt;'>4.1</span><span
                    style='font-size:8.0pt;'>(c)</span><span
                    style='font-size:8.0pt;'> <span lang=ZH-CN>条在合理合法的情况（如有）下进行的任何披露，并向对方提供合理的协助以避免或限制所需的披露。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(e)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>双方均有权将对方的机密信息披露给依据这些条款需要了解对方机密信息的己方员工、代理、主管、高级职员、授权分包商、专业顾问，但是接收信息的一方必须确保这些人员了解并遵守相关保密义务。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >媒体交流。</span></b><span
                >您不得在未获得我方提前书面批准的情况下，向媒体和</span><span
                    style='font-size:8.0pt;'>/<span lang=ZH-CN>或公众公布任何有关这些条款的新闻稿或其他交流。 </span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>4.3<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >数据保护。</span></b><span lang=ZH-CN style='font-size:8.0pt;
'>就第 </span><span style='font-size:8.0pt;'>4.3</span><span style='font-size:8.0pt;'>] <span lang=ZH-CN>条而言，以下术语具有如下含义：</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:
simple;text-autospace:none'><b><span lang=ZH-CN style='font-size:8.0pt;
'>适用的数据保护法</span></b><span >指适用于您的所有数据保护和隐私保护法律法规（如果您在欧洲经济区经营公司或使用数据保护设备，则包括欧盟指令</span><span style='font-size:8.0pt;'> 95/46/EC <span lang=ZH-CN>和</span> 2002/58/EC<span lang=ZH-CN>（包括其实施、修订或替代的版本））； </span></span></p>
                <h3 style='page-break-after:auto'><span lang=ZH-CN style='font-size:8.0pt;color:windowtext'>数据外泄</span><span lang=ZH-CN style='font-size:8.0pt;
color:windowtext;font-weight:normal;'>指任何对终端客户数据的未经授权的访问、损失、破坏、盗窃、使用或披露； </span></h3>
                <h3 style='page-break-after:auto'><span lang=ZH-CN style='font-size:8.0pt;color:windowtext'>数据管理者、数据处理者、个人数据和处理</span><span lang=ZH-CN style='font-size:8.0pt;
color:windowtext;font-weight:normal;'>采用适用的数据保护法规定的含义； </span></h3>
                <h3 style='page-break-after:auto'><span lang=ZH-CN style='font-size:8.0pt;color:windowtext'>终端客户数据</span><span lang=ZH-CN style='font-size:8.0pt;
color:windowtext;font-weight:normal;'>指与按照其预订查看、询问、预订或已居住在酒店的身份已识别或可识别的终端客户相关的任何信息，并且仅与本协议下处理的个人数据相关。</span></h3>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span
                    style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您保证自己已遵守或将遵守所有适用数据保护法。您将保证显示兼容且准确的隐私政策，并（在需要的情况下）在消费者面对的网站上显示网络跟踪器通知。 </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span
                    style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您须：</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;punctuation-wrap:simple;text-autospace:none'><span style='font-size:
8.0pt;'><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
                >采取适当的运营和技术措施以防止终端客户数据出现任何数据外泄；并且</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;punctuation-wrap:simple;text-autospace:none'><span style='font-size:
8.0pt;'><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
                >如果出现数据外泄，应及时通知另一方，详细告知外泄情况并向我方提供必要的合理配合以补救或降低数据外泄造成的影响。 <i> </i></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span
                    style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>您保证并承诺，您将：</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;punctuation-wrap:simple;text-autospace:none'><span style='font-size:
8.0pt;'><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
                >仅为履行您在本协议下的义务，获取、使用、传输和存储终端客户持卡人数据；</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;punctuation-wrap:simple;text-autospace:none'><span><span><span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>ii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                >在您获取、使用、传输、存储或处理终端客户持卡人数据时，您将遵守支付卡行业安全标准委员会颁发并不时更新的支付卡行业数据标准安全要求</span></span><span> (PCI DSS)<span lang=ZH-CN>；</span></span><span lang=ZH-CN style='font-size:8.0pt;
'> </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;punctuation-wrap:simple;text-autospace:none'><span style='font-size:
8.0pt;'><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; iii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
                >向我方提供一份您的年度合规证明；</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;punctuation-wrap:simple;text-autospace:none'><span style='font-size:
8.0pt;'><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; iv.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
                >及时通知我方对终端客户持卡人数据的任何</span><span style='font-size:8.0pt;'> PCI DSS <span lang=ZH-CN>违反情况或任何其他未经授权的访问情况。 </span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
                >免责声明</span></b></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
'><span >您同意</span><span style='font-size:8.0pt;'> EPS <span
                    lang=ZH-CN>在酒店预订方面不进行代表或保证。您同意</span> EPS <span lang=ZH-CN>明确免责所有由法律产生或有关酒店预订的隐含保证、义务和责任，包括但不限于：（</span>i<span
                    lang=ZH-CN>）适销性的暗示的保证，质量满意度或针对特定用途的；（</span>ii<span lang=ZH-CN>）默示担保所产生的从性能，交易过程或行业惯例的成本；或（</span>iii<span
                    lang=ZH-CN>）暗示的非侵权的保证。</span>  </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
'><span >您同意就下列问题，</span><span style='font-size:8.0pt;'>EPS <span lang=ZH-CN>对您或终端客户不承担任何责任：</span>(A) <span lang=ZH-CN>导致任何在处理预订的过程中产生故障或责任的我方或第三方系统故障；或</span> (B) <span lang=ZH-CN>由旅行提供商向终端客户提供的酒店预订质量。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
                >一般条款</span></b></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>6.1<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >适用法律和争议解决方法。</span></b><span
                >这些条款及所有权利和义务，包括结构，有效性，性能和争议解决事项，应按照英格兰和威尔士的实体法来约束和组构，不考虑法律，规则或原则可能发生的任何冲突需要任何其他法律或在实际执行这些条款时需要的任何修正。任何争议，纠纷或由此产生的或与这些条款或法律索赔，或违约，终止或无效，不能由双方协议，妥善加以解决，应最终由英格兰和威尔士的法院解决，以及缔约方提交这些法院的专属管辖权。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>6.2<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >修改。</span></b><span
                >我方可不时修改本协议。我方会将变更之处通知您，并会通过电子邮件或双方约定的其它方式向您提供修订后条款的复印件。若您对变更之处有异议，您应在</span><span
                    style='font-size:8.0pt;'> (i) <span lang=ZH-CN>我方成功发送电子邮件当日后的</span> 30 <span lang=ZH-CN>天内或</span> (ii)<span lang=ZH-CN>通过其他方式收到我方通知当日后的</span> 30 <span
                    lang=ZH-CN>天内，以电子邮件的形式通知我方。如果您在此时间段内未向我方发送通知，则我方将视您已接受变更之处，且变更之处将于修订条款中规定的日期生效。如果您告知我方您反对变更之处，则按照第 </span></span><span
                    style='font-size:8.0pt;'>3.2</span><span
                    style='font-size:8.0pt;'> <span lang=ZH-CN>条的规定，任何一方均可终止本协议。</span>  </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>6.3<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >不可抗力。</span></b><span>如果由于一方采取了合理的控制措施后仍无法进行合理避免和预防的原因而导致该方未履行或延迟履行这些条款项下的义务，则该方无需向对方承担任何责任，但始终应遵循一个前提，即：受此情况影响的一方应及时通知对方相应原因和可能会在多长时间内无法履行或延迟履行相应义务，并应采取所有合理措施（包括实施业务持续性和灾难恢复计划）以尽快解决造成无法履行或延迟履行义务的问题。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>6.4<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >非弃权。</span></b><span
                >任何关于这些条款期限、条件或义务的弃权声明均无效。没有失败或延误任何一方在任何时候实施其中一个条款，条件或本协议将义务或以上：（</span><span
                    style='font-size:8.0pt;'>a<span lang=ZH-CN>）构成放弃该等条款，条件或义务；（</span>b<span
                    lang=ZH-CN>）排除这方的要求被对方表现在以后的任何时间；或（</span>c<span lang=ZH-CN>）被视为是否喜欢或不同性质是放弃任何其他后续期限，条件或义务。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>6.5<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >分配。</span></b><span
                >在没有合理理由的情况下，这些条款不得被任何一方未经另一方事先书面同意分配给他人。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>6.6<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >可分割性。</span></b><span
                >这些条款将被强制执行在适用法律允许的最大范围。若这些条款的任何规定无效或不可执行，应在合理范围内对其予以诠释、解释或修改，以使其生效、可执行且符合其原始意图。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span
                    style='font-size:8.0pt;'>6.7<span
                    style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span lang=ZH-CN style='font-size:8.0pt;
'>整体协议。</span></b><span
                >如果第 </span><span style='font-size:8.0pt;'>6.7</span><span style='font-size:8.0pt;'> <span lang=ZH-CN>条不因虚假描述而限制一方的责任，那么这些条款构成双方间整个和独特的协议，内容有关此处主题，凌驾于一切事先或同期的口头或书面协议和协定。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>6.8<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >第三方权利。</span></b><span >您同意并确认，</span><span
                    style='font-size:8.0pt;'>EPS<span lang=ZH-CN>、</span>Travelscape
    LLC <span lang=ZH-CN>和</span> VacationSpot SL <span lang=ZH-CN>及每位集团成员均能享受这些条款下的权益。尽管如此，双方同意，若</span> EPS<span lang=ZH-CN>、</span>Travelscape LLP<span lang=ZH-CN>、</span>Vacation
    Spot SL <span lang=ZH-CN>和</span>/<span lang=ZH-CN>或美味集团成员蒙受损失，则我方可为或代表受影响的集团成员提出索赔（如可能），且双方同意，我方可为此担任该集团成员的代理并代表该集团成员处理任何索赔。本条款未授权</span> EPS<span lang=ZH-CN>、</span>Travelscape LLC <span lang=ZH-CN>和</span> VacationSpot SL <span lang=ZH-CN>和</span>/<span lang=ZH-CN>或任何其集团成员就同一损失或违约获得一次以上的赔偿或任何其他形式款项或补偿。</span></span><i></i></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>6.9<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >通知。</span></b><span
                > </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(a)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>一方授权的发送人应按照通知的地址向另一方，以电子邮件书面通知。 </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(b)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>电子邮件通知生效时间如下：</span><span style='font-size:8.0pt;
'>(i) <span lang=ZH-CN>如果在某工作日的下午</span> 4:30 <span
                    lang=ZH-CN>之前发送，则生效时间为发送当天；或者</span> (ii) <span lang=ZH-CN>任何收件方的授权收件人承认已收到邮件，或在没有此类认可的形况下，生效时间为发送</span> 3 <span lang=ZH-CN>小时后（以发送方电子邮件应用程序记录的时间为准），除非收到时间是某工作日下午</span> 4:30 <span
                    lang=ZH-CN>以后。这种情况下，默认生效时间为第二个工作日的上午</span> 10:00<span lang=ZH-CN>；且</span> (iii) <span lang=ZH-CN>所有收件方的授权收件人均未针对电子邮件通知，收到错误（包括发送错误信息）或显示收件人未在办公室的有效回复邮件。</span></span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
                >定义与解释</span></b></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>7.1<span style='font:7.0pt "Times New Roman"'> </span></span></b><b><span
                >定义。</span></b><span
                >在这些条款中，适用以下定义：</span></p>
                <p style='
text-align:justify;'><b><span >适用法律：</span></b><span
                >如这些条款中第 </span><span
                    style='font-size:8.0pt;'>1.6</span><span
                    style='font-size:8.0pt;'> <span lang=ZH-CN>条的定义；</span></span></p>
                <p style='
text-align:justify;'><b><span >已获认可的平台</span></b><span
                >：您用于营销酒店预订的网站和</span><span
                    style='font-size:8.0pt;'>/<span lang=ZH-CN>或平台； </span></span></p>
                <p style='
text-align:justify;'><b><span >预订：</span></b><span
                >通过已获认可的平台在酒店预订上完成的预订；</span></p>
                <p style='
text-align:justify;'><b><span style='font-size:8.0pt;'>EPS</span></b><span
                >：指</span><span
                    style='font-size:8.0pt;'> EPS.com LP</span></p>
                <p style='
text-align:justify;'><b><span >电子收费预订：</span></b><span
                >您或我方从终端客户收费的预订</span></p>
                <p style='
text-align:justify;'><b><span >终端客户：</span></b><span
                >预订的个人或公司消费者，或者其代表；</span></p>
                <p style='
text-align:justify;'><b><span >终端客户问题：</span></b><span
                >如这些条款中第 </span><span
                    style='font-size:8.0pt;'>1.5</span><span
                    style='font-size:8.0pt;'>(b)</span><span
                    style='font-size:8.0pt;'> <span lang=ZH-CN>条的定义；</span></span></p>
                <p style='
text-align:justify;'><b><span >终端客户条款和条件</span></b><span
                >：适用于预订的条款和条件，包括一切适用隐私政策，我方会即使向您发送更新和通知；</span></p>
                <p style='
text-align:justify;'><b><span >集团成员：</span></b><span
                >直接或间接通过一个或多个中介控制或被控制，或者由某个实体一般控制的实体；</span></p>
                <p style='
text-align:justify;'><b><span >酒店预订</span></b><span
                >：使用</span><span
                    style='font-size:8.0pt;'> EPS <span lang=ZH-CN>及其集团成员即时向我方提供的打分和可用信息（由其自行斟酌决定）进行住宿预订；</span></span></p>
                <p><b><span
                >套餐：</span></b><span
                >住宿加交通预订（仅限飞机、火车或汽车）</span></p>
                <p><b><span
                >套餐产品：</span></b><span
                >如这些条款中第 </span><span
                    style='font-size:8.0pt;'>1.7</span><span
                    style='font-size:8.0pt;'> <span lang=ZH-CN>条的定义；</span></span></p>
                <p><b><span
                >条款</span></b><span
                >：如这些条款中第 </span><span
                    style='font-size:8.0pt;'>3.1</span><span
                    style='font-size:8.0pt;'> <span lang=ZH-CN>条中定义；</span></span></p>
                <p><b><span
                >工作日：</span></b><span
                >周一至周五，公共假日除外。</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:normal;
punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;'>7.2<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
                >解释。 </span></b></p>
                <h2 style='text-align:justify;page-break-after:auto'><span
                    lang=ZH-CN style='font-size:8.0pt;color:windowtext;font-weight:normal;
'>在本协议中，除非上下文另有要求，否则： </span></h2>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(a)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>所提及的章节或表单均为这些条款中的章节和表单。插入标题仅为便于参考，不得影响对协议的解释； </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(b)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>人士包括自然人、事务所、合伙人、公司和企业；表示某一性别的词应包括所有其他性别，表示单数的词应包括复数形式，反之亦然； </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(c)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>提及法规、法定文书及政府规章时，应视为包括其不时进行的修改、修订、延期或重新颁布； </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(d)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>“包括”、“包含”、“尤其”、“例如”和任何类似措辞不得视为限制其前一个词语； </span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(e)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>涉及书面和以书面形式时，则应包括以电子邮件形式获得的通信（包括电子邮件中的所有相关附件）；</span></p>
                <p class=MsoListParagraph style='text-align:justify;line-height:
normal;punctuation-wrap:simple;text-autospace:none'><b><span style='font-size:8.0pt;font-family:"Arial Narrow",sans-serif;
"Arial Narrow"'>(f)<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span lang=ZH-CN style='font-size:8.0pt;
'>提及任何协议或文件时，则指双方不时通过书面协议修订或变更的协议或文件。</span></p>
              </div>

            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="container p-t-2 p-b-2">
          <div class="row">
            <div class="column">
              <h2>AGENT TERMS OF USE
              </h2>
              <p>These Agent Terms of Use (these Terms) govern the terms upon which you market Hotel Information and undertake Bookings for End Customers through the Approved Platforms.<br></p>
              <p>Please read these Terms. Defined terms are indicated by capitalization, and where these terms are not defined in the text, they are defined at the end of these Terms. By offering Hotel Information and undertaking Bookings for End Customers through the Approved Platform you agree, acknowledge and represent to us that:<br></p>
              <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your representative has read and understood these Terms on your behalf;<br></p>
              <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You agree to be bound by these Terms without modification in relation to your access to the Hotel Information through the Approved Platform and the offer to undertake Bookings for End Customers; and<br></p>
              <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your representative entering into the agreement created by acceptance of these Terms has the power, capacity and authority to enter into these Terms and to bind you.<br></p>
              <p>&nbsp;
              </p>
              <h3>1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; AGENT OBLIGATIONS
              </h3>
              <p>1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Agent Marketing. During the Term, you shall actively market and promote the Hotel Information and the undertaking of Bookings to End Customers.<br></p>
              <p>1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Content. You will not change, amend or misrepresent any of the information, content or data relating to or in connection with the Hotel Information that is provided to you through the Approved Platforms (Content).<br></p>
              <p>1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access to the Hotel Information and Approved Platform. You must:<br></p>
              <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; only: (i) access the Hotel Information through the Approved Platform; and (ii) procure Bookings; in a manner consistent with the Code of Conduct in Section 2 of these Terms, EPS’s policies and rules and our or EPS’s instructions as notified to you from time to time;<br></p>
              <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; only input the End Customer’s data in the Approved Platform and solely for the purpose of procuring Bookings; and<br></p>
              <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ensure that the End Customer’s data and any other information submitted to the Approved Platform is complete and accurate.&nbsp;&nbsp;<br></p>
              <p>1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Customer Contracts. Prior to the completion of a Booking, you must:<br></p>
              <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; make clear to End Customers that Travelscape, LLC or Vacationspot, SL (or any other Group Member of EPS) shall be treated by any tax authority in any pertinent jurisdiction as the supplier to the End Customer of E-Collect Bookings and accommodation component of a Package for VAT (Value Added Tax) purposes as applied by Directive 2006/112/EC;&nbsp;<br></p>
              <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not display or make any statement which expressly or by implication contradicts Section 1.4(a) of these Terms;<br></p>
              <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ensure that the End Customer has read and agrees to be bound by the End Customer Terms and Conditions;&nbsp;<br></p>
              <p>(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ensure that the End Customer is aware of any limitations or restrictions that apply to their Booking, including whether the End Customer is allowed to make any cancellations or changes to their Booking and the consequences of the same;<br></p>
              <p>(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not make any verbal or written assurances to an End Customer which are additional to or are contrary to: (i) these Terms; or (ii) the End Customer Terms and Conditions; which includes promising an End Customer that their special request will be met; and<br></p>
              <p>(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not cancel or amend any Bookings made by Agents except on the express instruction of the End Customer.<br></p>
              <p>You shall be solely responsible and liable to use in respect of any representations or special requests made or confirmed to the Customer without our prior written authorisation and approval.<br></p>
              <p>1.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Customer Communications and Complaints.<br></p>
              <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You shall within 24 hours of receipt: (i) provide to the End Customer, without amendment or modification, all information related to a Booking; and (ii) provide to us, without amendment or modification: (1) all communications received related to a Booking; (2) any complaints received from End Customers; and (3) any communications received from Trading Standards or any other regulatory trade body which relate to an End Customer and/or any Booking.&nbsp;&nbsp;</p>
              <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You shall notify us within 24 hours if you receive any End Customer complaint, claim or action regarding a Booking (each an End Customer Issue). You acknowledge and agree that we shall have the right to attempt to settle any End Customer Issue directly with the End Customer. If we attempt such a settlement, you must, at your expense, provide us with all information and reasonable assistance we require to reach settlement and you shall refrain from taking any other action in respect of the End Customer Issue; unless required to do so by Applicable Law.</p>
              <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In order for us to support End Customers in the event that the accommodation supplier comprising the Hotel Information is not able to honour any Booking, you: (a) will continue to provide us with your up to date contact details for us to notify you of any relocations; (b) will cooperate with us to facilitate a relocation, including promptly liaising with End Customers; (c) agree that relocations will be in accordance with EPS’s standard process from time to time.</p>
              <p>1.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Compliance with Laws. You shall comply with all applicable laws in force from time to time relevant to the performance of your obligations under these Terms including, without limitation, any legislation relating to the licensing of travel agents (Applicable Laws).</p>
              <p>1.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Package Bookings. Provided we give our written consent to you (directly or indirectly), certain Hotel Information that we specify from time to time (Package Rates) may be made available to you for booking as part of a Package. We may cease to offer Package Rates at any time by providing you (directly or indirectly) with no less than seven (7) days’ written notice.&nbsp;</p>
              <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Booking of Package Rates. You shall: (a) not book, nor make available or offer Package Rates except as part of a Package; (b) ensure that the final booking price for a Package Rate is equal to the rate we provide for such Package Rate (and you acknowledge that you remain responsible for the final price of the Package); (c) not display or otherwise communicate separate pricing of Package Rates to End Customers&nbsp; at any time during the booking or confirmation processes; (d) ensure the Package Rate can only be applied in relation to the same trip to which the Approved Transport Component within the Package relates (including having no more rooms booked than the number of people to which the Approved Transport Component relates); and (e) retain proof of the inclusion of the Approved Transport Component within the Package for at least 30 days after the Booking is made.&nbsp;</p>
              <p>We may from time to time require you at your own cost to demonstrate your ongoing compliance with this Section 1.7(a). You shall upon our written request, send copies of booking confirmations, booking details and give access to such other information, systems and/or documentation as is reasonably necessary to demonstrate your compliance with this Section 1.7(a).</p>
              <p>Without prejudice to any other rights or remedies available to us or our Group Members, if you are in breach of this Section 1.7(a), we may restrict access to Package Rates with immediate effect and may terminate these Terms.</p>
              <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Compliance with Law. You undertake and warrant that you shall be responsible for obtaining and maintaining all licences, consents and other permissions (each, if any and whether regulatory or otherwise) and all financial security arrangements necessary for the performance of obligations under these Terms in respect of bookings of Packages and/or linked travel arrangements provided and/or arranged by you. You will comply with all applicable laws (including European Council Directive 90/314/EEC European Directive (EU) 2015/2302 and all relevant local implementations of it) and any other current or future laws relating to package travel or linked travel arrangements in any jurisdiction.. You shall co-operate as necessary to provide the necessary information allowing us each to discharge our obligations with regard to any packaged travel product or linked travel arrangement.&nbsp;
              </p>
              <p>You agree to provide all necessary assistance and support in the event of an enquiry, request for information or investigation into the making available of Packages and/or linked travel arrangements by any national authority, consumer body or other interested party.<br></p>
              <p>1.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Agent Booking Fee.&nbsp; If you add a booking fee to the total amount charged to the End Customer, you shall ensure:<br></p>
              <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the booking fee is not aggregated into the room rates, taxes, or service fees provided in the Approved Platform,</p>
              <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; you make it clear to the End Customer that the booking fee is applied by you only;</p>
              <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; you include the following text in your booking terms and conditions in respect of the Booking: “The total price may include an additional booking fee from [INSERT YOUR NAME]”, and&nbsp;</p>
              <p>(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; you do not include the booking fee in any supplier selection algorithm or sort order logic.
              </p>
              <p>&nbsp;
              </p>
              <h3>2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CODE OF CONDUCT
              </h3>
              <p>2.1&nbsp;&nbsp;&nbsp; You shall not send unsolicited bulk email, “spam” or otherwise engage in any other unethical or illegal marketing activities (as determined by us or EPS, acting reasonably) concerning End Customers in any jurisdiction.<br></p>
              <p>2.2&nbsp;&nbsp;&nbsp; You shall not:<br></p>
              <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; place any material on any customer facing website or associate Hotel Information with any material or opinions that are illegal in any jurisdiction or are otherwise discriminatory, promote or incite violence, hatred or an illegal activity, are capable of interpretation as discriminatory or of promoting such views, or are inappropriate for general or family viewing (e.g. sexually explicit materials);<br></p>
              <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; mislead or misrepresent to consumers as to the origin, affiliation or nature of your websites, products or services;<br></p>
              <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; sell, redistribute, display or use in any context or manner (directly or indirectly), any data or materials from EPS or its Group Members’ websites (including any Content);<br></p>
              <p>(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; sell, redistribute, display or use any data, materials or other content from any website owned or operated by EPS or our Group Members other than in accordance with the terms of this Agreement.<br></p>
              <p>(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; use EPS trademarks or those of its Group Members (including any misspelling or substantially similar or confusingly similar version thereof), in any manner whatsoever (including without limitation, in any domain or sub-domain name, any other online/offline marketing or advertising, press releases, etc.) without first obtaining EPS's prior written approval;<br></p>
              <p>2.3&nbsp;&nbsp;&nbsp; Predatory Advertising. You will not use any predatory advertising methods. Predatory advertising means any method that creates or overlays links or banners on websites, mobile devices, social media or any other channel which allows access to Hotel Information and Content (each a channel), spawns browser windows, or any method invented to generate traffic from a channel without that channel owner’s knowledge, permission, and participation.</p>
              <p>2.4&nbsp;&nbsp;&nbsp; Keyword Advertising. With respect to any “pay for sponsored advertising placement” keyword purchase program offered by an internet search engine: neither you, nor your Group Members, shall bid on any of our marks that are listed in Annex 1 (or any misspellings of such trademarks). (or any regional variants), for preferential placement in any cost per click search engine or other search engine in which search result page listing order is determined by payment to the search engine or other third party, and will not use any such names in keyword meta tags on any pages of the approved websites or any other websites or channels you own or operate.&nbsp; If requested, you shall promptly cease bidding on or otherwise using any name or trademark of any accommodation supplier comprising Hotel Information, or to register a negative keyword of such supplier, then you will, at our request promptly cease the use or bidding in question upon such name or names, or register such negative keyword.</p>
              <p>2.5&nbsp;&nbsp;&nbsp; You shall not represent yourself as acting on behalf of EPS or any of its Group Members.</p>
              <p>2.6&nbsp;&nbsp;&nbsp;&nbsp; You shall not misrepresent who you are acting for when contacting End Customers including (as an example only) leading End Customers to believe that you are directly connected to any of the accommodation suppliers comprising Hotel Information.</p>
              <p>2.7&nbsp;&nbsp;&nbsp; You acknowledge and agree that Travelscape, LLC or Vacationspot, SL (or any other Group Member EPS designates) shall be treated by any Tax authority in any pertinent jurisdiction as the supplier to the End Customer of E-Collect Bookings and the accommodation component of a Package for VAT (Value Added Tax) purposes as applied by Directive 2006/112/EC</p>
              <p>2.8&nbsp;&nbsp;&nbsp; You shall not directly contact any of the accommodation suppliers comprising Hotel Information in relation to Hotel Information or Bookings. Any direct communications from such accommodation suppliers which you receive should be referred immediately to us.</p>
              <p>2.9&nbsp;&nbsp;&nbsp; Any advertising and promotional materials used in connection with the Hotel Information shall be provided by or expressly approved in writing by us.</p>
              <p>2.10&nbsp; You shall notify us of any and all customer complaints made to you relating to the VAT treatment of E-Collect Bookings, and must not attempt to deal with such complaints yourself. You shall also refer to us any enquiries from tax authorities or any other government agencies in relation to these Terms or the E-Collect Bookings, and shall provide us with the opportunity to review and/or contribute to any response to such enquiries.</p>
              <p>2.11&nbsp; You shall not misrepresent any of the terms of the contract relevant to services being provided under these Terms, including product descriptions.</p>
              <p>2.12&nbsp; You acknowledge that Bookings of more than 8 rooms with the same accommodation supplier for the same stay dates (a Group Booking) may not be made by you or End Customers. In the event you, your Agent or an End Customer requires a Group Booking, you will notify us and follow our relevant process. Any Group Booking made in breach of this clause may be cancelled by us and any applicable cancellation fees will be applied.</p>
              <h3>3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; TERM AND TERMINATION</h3>
              <p>3.1&nbsp;&nbsp;&nbsp; These Terms shall commence on the earlier of: (i) your acceptance of these Terms; or (ii) your first Booking; and subject to earlier termination in accordance with Section 3.2, shall continue until expiry or termination of our relationship with you or EPS’s relationship with us (Term).</p>
              <p>3.2&nbsp;&nbsp;&nbsp; We in our sole discretion and without cause may terminate these Terms immediately upon written notice for any reason. You may terminate these Terms on thirty (30) days’ written notice to us. The rights and remedies provided in this Section 3.2 are not exclusive and are in addition to any other rights and remedies provided by law or these Terms.
              </p>
              <p>3.3&nbsp;&nbsp;&nbsp; Upon termination or expiration of these Terms for any reason: (i) you shall immediately cease procuring Bookings; and (ii) we may immediately shut down your account. Sections 3.3, 4, 5, and 6 shall survive any expiration or termination of these Terms.</p>
              <h3>4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CONFIDENTIALITY; MEDIA COMMUNICATIONS, DATA PROTECTION
              </h3>
              <p>4.1&nbsp;&nbsp; &nbsp;Confidentiality<br></p>
              <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For the purpose of this clause, Confidential Information means any and all know-how, documentation and information, whether commercial, financial, technical, operational or otherwise, relating to the business, finances, affairs, tools (including those provided on a technology), products, services, personnel, customers, suppliers (including precincts, future and potential personnel, customers and suppliers), prices, commissions, rates, vendors, processes, or methods of one party or its Group Members (and EPS or its Group Members), which is disclosed to or otherwise obtained by the other party in connection with these Terms and the existence, terms and subject matter of these Terms.</p>
              <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Both you and we&nbsp; (each a Party and together the Parties) shall keep the other's Confidential Information confidential and shall not divulge the same to any third party, except EPS or EPS’s Group Members, unless divulging for the purposes of these Terms, or use it itself for any other purpose without the prior written consent of the other Party.</p>
              <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Section 4.1(b) shall not apply to any Confidential Information that the receiving Party can show: (i) is in the public domain in substantially the same combination as that in which it was disclosed to the receiving Party other than as a result of a breach of these Terms or any other obligations of confidentiality; (ii) is or was lawfully received from a third party not under an obligation of confidentiality with respect thereto; (iii) is required to be disclosed under operation of law, by court order or by any regulatory body of competent jurisdiction (but then only to the extent and for the purpose required); (iv) is approved for disclosure in writing; or (v) was developed independently of and without reference to Confidential Information disclosed by the other Party; provided that a particular disclosed or discovered use, combination, analysis, form or collection of information will not be in the public domain simply because it could be re-created using information in the public domain.</p>
              <p>(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Each Party shall give the other as much notice of any disclosure required under Section 4.1(c)as is reasonable and lawful in the circumstances (if any) and shall provide the other with reasonable assistance in avoiding or limiting the required disclosure.</p>
              <p>(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Each Party shall be entitled to divulge the other Party's Confidential Information to its employees agents, directors, officers, authorised sub-contractors, professional advisors and consultants who have a need to know the same in connection with these Terms provided that the receiving Party shall ensure that such persons are aware of and, shall procure that such persons comply with, these obligations as to confidentiality.</p>
              <p>4.2&nbsp;&nbsp;&nbsp; Media Communications. You shall not release to the public any press release or other communication to the press and/or public regarding these Terms without our prior written consent.</p>
              <p>4.3&nbsp;&nbsp;&nbsp; Data Protection. For the purposes of Section 4.3, the following terms will have the following meanings:</p>
              <p>Applicable Data Protection Law(s) means all data protection and privacy that apply to End Customer Data processed under this Agreement (including, where applicable, EU Data Protection Laws);</p>
              <p>End Customer Data means any personal data relating to an End Customer who views, enquires about, books or has stayed at a hotel in accordance with their Booking that is processed pursuant to these Terms.
              </p>
              <p>EU Data Protection Laws means: (i) prior to 25 May 2018, EU Directives 95/46/EC and 2002/58/EC (each as amended), and any applicable national implementations of them; and (ii) on and after 25 May 2018, EU Regulation 2016/679 (the General Data Protection Regulation) and any applicable national legislation made under or in connection with the General Data Protection Regulation; and
              </p>
              <p>Security Incident means a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data transmitted, stored or otherwise processed.</p>
              <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You warrant that you have complied with and will comply with all Applicable Data Protection Law(s).You will ensure that you display a compliant and accurate privacy policy and cookie notice (if required) on your customer facing websites.</p>
              <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You shall:</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; maintain appropriate technical and organizational measures to protect the End Customer Data against any Security Incident; and
              </p>
              <p>&nbsp;
              </p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; in the event of a confirmed Security Incident, promptly notify us if the Security Incident affects End Customer Data, providing full details of the same. In such event, both parties shall cooperate reasonably and in good faith to remedy or mitigate the effects of the Security Incident, and the reasonable costs of such cooperation shall be borne by the party that suffered the Security Incident.&nbsp;</p>
              <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You warrant and represent that you will:</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; only obtain, use, transmit and store End Customer cardholder data to the extent required to comply with your obligations under this Agreement;</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; where you obtain, use, transmit, store or process End Customer’s cardholder data, you will comply with the Payment Card Industry Data Standard Security Requirements (PCI DSS) as issued by the PCI Security Standards Council, as updated from time to time;</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; iii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; provide us with a copy of your annual certification of compliance;</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; iv.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; promptly notify us of any breach of the PCI DSS or any other Security Incident affecting End Customer cardholder data.</p>
              <h3>5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; COMPLIANCE
              </h3>
              <p>We or EPS shall be entitled to verify your performance under these Terms at any time upon reasonable notice (save where we or EPS (acting reasonably) suspect a material breach of these Terms, fraud and/or a breach of law in which case there shall be no requirement to give prior notice). You shall permit and/or shall procure that we or EPS are permitted access to such of your and your sub-contractors' and agents' records, systems and information as we or EPS may require for the purpose of such verification.</p>
              <p>Without prejudice to the foregoing, upon request you will promptly provide us or EPS with copies of such records, accounts and information as we or EPS require to verify your performance or compliance under these Terms.</p>
              <h3>6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DISCLAIMER&nbsp;</h3>
              <p>YOU AGREE THAT EPS MAKES NO REPRESENTATIONS OR WARRANTIES WITH REGARD TO THE HOTEL INFORMATION. YOU AGREE THAT EPS EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES, OBLIGATIONS AND LIABILITIES ARISING BY LAW OR OTHERWISE, WITH RESPECT TO THE HOTEL INFORMATION, INCLUDING WITHOUT LIMITATION ANY: (i) IMPLIED WARRANTY OF MERCHANTABILITY, SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE; (ii) IMPLIED WARRANTY ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE OF TRADE; OR (iii) IMPLIED WARRANTY OF NON-INFRINGEMENT.</p>
              <p>YOU AGREE THAT EPS WILL HAVE NO LIABILITY TO YOU OR ANY END CUSTOMERS RELATING TO: (A) ANY FAILURE OF THE SYSTEMS OF US OR ANY THIRD PARTY THAT RESULTS IN THE FAILURE OR INABILITY TO PROCESS A BOOKING; OR (B) THE QUALITY OF THE HOTEL INFORMATION PROVIDED BY TRAVEL SUPPLIERS TO END CUSTOMERS.</p>
              <h3>7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GENERAL</h3>
              <p>7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Governing Law and Dispute Resolution. These Terms and all rights and obligations hereunder, including matters of construction, validity, performance and dispute resolution, shall be governed by and construed in accordance with the substantive laws of England and Wales without regard to any conflict of laws, rules or principles that may require the application of any other law or where these Terms or any amendment to it is actually executed. Any dispute, controversy or claim arising out of or in relation to these Terms or at law, or the breach, termination or invalidity thereof, that cannot be settled amicably by agreement between the Parties, shall be finally settled by the Courts of England and Wales, and the Parties submit to the exclusive jurisdiction of those courts.</p>
              <p>7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Economic Sanctions. You warrants and represents on a continuing basis that it and its Group Members are not incorporated in or resident in a country, or listed as a "Specially Designated National," a "Specially Designated Global Terrorist," a "Blocked Person," or similar designation under the economic or trade sanctions imposed by the by the U.S. Treasury Department's Office of Foreign Assets Control (OFAC), the United Nations Security Council, the European Union and her Majesty’s Treasury (the Economic Sanctions, and any relevant individual being a Restricted Person). You will not act in such a way so as to bring us or EAN into breach of any Economic Sanctions (including marketing to Restricted Persons), and will notify us immediately in respect of any breach of this clause. You warrant that you will be liable for any act made by you or your Agent that would otherwise threaten to bring us into breach of any Economic Sanctions, and agree that any breach of this clause shall be deemed a material breach not capable of remedy and we may immediately terminate this Agreement in such an event.&nbsp;</p>
              <p>7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Modification. We may make changes to this Agreement from time to time.&nbsp; We will notify you of any changes and provide you with a copy of the revised terms by email or such other means as may have been agreed between the parties.&nbsp; If you do not agree with the changes, you must notify us by email within 30 days of the date of (i) successful dispatch of our email or (ii) receipt of our notice if sent by any other method.&nbsp; If you fail to do so within this time period you shall be deemed to have accepted the changes and the changes will come into effect on the date set out in the revised terms.&nbsp; If you notify us that you object, either Party may terminate this Agreement in accordance with Section 3.2.&nbsp;</p>
              <p>7.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Force Majeure. Neither Party shall be liable to the other for any failure or delay in the performance of its obligations under these Terms (save in respect of any obligation to pay any monies due) to the extent that such failure or delay arises due to reasons beyond such Party's reasonable control which that Party is unable to reasonably avoid or provide against provided always that the affected Party promptly notifies the other of the cause and likely duration of the failure or delay and takes all reasonable steps, including implementation of its business continuity and disaster recovery plan to overcome the failure or delay as soon as possible.&nbsp;</p>
              <p>7.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Non-waiver. No waiver of any term, condition or obligation of these Terms will be valid. No failure or delay by any Party at any time to enforce one or more of the terms, conditions or obligations of these Terms will: (a)&nbsp;constitute waiver of such term, condition or obligation; (b)&nbsp;preclude such Party from requiring performance by the other Party at any later time; or (c)&nbsp;be deemed to be a waiver of any other subsequent term, condition or obligation, whether of like or different nature.</p>
              <p>7.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Assignment. These Terms may not be assigned by either Party without the prior written consent of the other Party not to be unreasonably withheld.</p>
              <p>7.7 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Severability. These Terms will be enforced to the fullest extent permitted by Applicable Law. If any provision of these Terms is held to be invalid or unenforceable, then such provision will be interpreted, construed or reformed to the extent reasonably required to render the same valid, enforceable and consistent with the original intent underlying such provision.&nbsp;</p>
              <p>7.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Entire Agreement. These Terms constitute the entire and exclusive agreement between the Parties regarding the subject matter hereof, and supersede all prior or contemporaneous oral or written agreements and understandings; provided nothing in this Section 7.8 shall limit a Party’s liability for any representations made fraudulently.<span></span>
              </p>
              <p>Third Party Rights. You agree and acknowledge that EAN, Travelscape LLC, and VacationSpot SL and each of its Group Members shall have the benefit of these Terms. Notwithstanding this, the Parties agree that any claims in respect of losses suffered by EAN, Travelscape LLP, Vacation Spot SL and/or any of their Group Members shall where possible be brought by us for and on behalf of the affected Group Member and the Parties agree that, for this purpose, we shall be agent for and conduct any claims on behalf of such Group Member. Nothing in this clause shall enable EAN, Travelscape LLC, and VacationSpot SL and each of their Group Members to be entitled to recover damages or obtain any other form of payment or compensation more than once in respect of the same loss or breach.</p>
              <p>7.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Notice.</p>
              <p>(a)&nbsp;&nbsp;&nbsp;&nbsp; Notices must be given in English by email by a Party's authorised sender to the addresses notified to the other Party from time to time.   </p>
              <p>(b)&nbsp;&nbsp;&nbsp;&nbsp; An email notice shall take effect: (i) if sent before 4.30pm on a Working Day, on the day of transmission; or (ii) when receipt is acknowledged by any of the receiving party's authorised recipients, or in the absence of such acknowledgment, 3 hours after the time of transmission (as recorded by the sending party's email application) save where such time of receipt would fall after 4.30pm on a Working Day, in which case it shall be deemed received at 10am on the next Working Day; and where (iii) no error (including any delivery error message) or valid out-of-office email message is received by the sending party in response to such email notice in respect of all of the receiving party's authorised recipients.
              </p>
              <h3>8&nbsp;&nbsp;&nbsp;&nbsp; DEFINITIONS AND INTERPRETATION</h3>
              <p>8.1&nbsp; Definitions. In these Terms, the following definitions apply:
              </p>
              <p><strong>Applicable Laws: </strong>as defined at Section 1.6 of these Terms;
              </p>
              <p><strong>Approved Platform</strong>: the websites and/or platforms you use to view Hotel Information and Content and undertake Bookings;
              </p>
              <p><strong>Approved Transport Component: </strong>one of the following travel services, separately provided by you or your third-party supplier: (i) air travel, (ii) rail travel which constitutes a substantial portion of the Package, (iii) car rental for at least the full duration of the hotel stay period of the Booking, or (iv) an overnight cruise;
              </p>
              <p><strong>Booking: </strong>a reservation made through the Approved Platform on the basis of the Hotel Information;
              </p>
              <p><strong>EPS</strong>: means EAN.com LP;
              </p>
              <p><strong>E-Collect Booking: </strong>A Booking for which you or we collect the payment from the End Customer
              </p>
              <p><strong>End Customer: </strong>the individual or corporate customer undertaking the Booking;
              </p>
              <p><strong>End Customer Issue: </strong>as defined at Section 1.5(b) of these Terms;
              </p>
              <p><strong>End Customer Terms and Conditions</strong>: the terms and conditions applicable to Bookings, including any applicable privacy policy, as updated and notified to you from time to time;
              </p>
              <p><strong>Group Member: </strong>an entity that, directly or indirectly, through one or more intermediaries, controls or is controlled by, or is under common control with an entity;
              </p>
              <p><strong>Hotel Information</strong>: the rate and availability information that EAN and its Group Members make available (at its sole discretion) from time to time in the Approved Platform;
              </p>
              <p><strong>Package: </strong>the booking of accommodation together with an Approved Travel Component;
              </p>
              <p><strong>Package Rates: </strong>as defined at Section 1.7 of these terms;
              </p>
              <p><strong>Term</strong>: as defined at Section 3.1 of these terms;
              </p>
              <p><strong>Working Day</strong>: Monday to Friday excluding bank holidays in England.
              </p>
              <p>8.2&nbsp; Interpretation.
              </p>
              <h2>In these Terms, except where the context requires otherwise: </h2>
              <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; references to sections or schedules shall be to sections of and schedules to these Terms. Headings are inserted for ease of reference and shall not affect interpretation;   </p>
              <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; persons includes natural persons, firms, partnerships, companies, corporations, and words suggesting a gender shall include all other genders, words denoting the singular shall include the plural and vice versa;   </p>
              <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; references to statutes, statutory instruments and government regulations shall be deemed to include any modification, amendment, extension or re-enactment thereof from time to time, unless otherwise specified, the provisions of any statute, regulation or rule which amends, supplements or supersedes any such statute, regulation or rule;
              </p>
              <p>(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the expressions including, include, in particular, for example and any similar expression are meant to be inclusive and shall not limit the preceding words, and the word “or” is disjunctive, but not necessarily exclusive;   </p>
              <p>(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; references to writing and written include communication by email including all related attachments;
              </p>
              <p>(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a reference to any agreement or document means that agreement or document as amended or varied by written agreement between the Parties from time to time.
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "EpsTermsAgent",
  data(){
    return {
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
    }
  }
}
</script>

<style scoped>

</style>